import { gql } from 'react-apollo';

const HOMEPAGE_METATAGS_FRAGMENT = gql`
  fragment HomepageMetatagsFragment on RootQueryToHomePageConnection {
    nodes {
      id
      metaTags {
        id: title
        title
        description
        image {
          id: mediaItemUrl
          mediaItemUrl
        }
      }
    }
  }
`;
export const GET_CMS_HOMEPAGE_METATAGS = gql`
  query GetCMSHomepageMetatags($languageCode: String) {
    homePages(first: 1, where: { languageCode: $languageCode, postSlug: "home" }) {
      ...HomepageMetatagsFragment
    }
  }
  ${HOMEPAGE_METATAGS_FRAGMENT}
`;

export const GET_CMS_EXPORT_CONNECT_HOMEPAGE = gql`
  query GetCMSExportConnectHomepage($languageCode: String, $postSlug: String) {
    homePages(first: 1, where: { languageCode: $languageCode, postSlug: $postSlug }) {
      ...HomepageMetatagsFragment
      nodes {
        id
        title
        header {
          id: headertitle
          fieldGroupName
          headersubtitle
          headertitle
          headerimage {
            id
            mediaItemUrl
            altText
          }
        }
        extraContent {
          statssection {
            id: title
            title
            text
            icon {
              id
              mediaItemUrl
            }
          }
        }
      }
    }

    posts(first: 3, where: { languageCode: $languageCode }) {
      nodes {
        id
        postIdentification {
          id: postslug
          postslug
        }
        title
        slug
        content
        featuredImage {
          node {
            id: databaseId
            mediaItemUrl
          }
        }
      }
    }

    faqs(first: 4, where: { languageCode: $languageCode }) {
      nodes {
        id
        postIdentification {
          id: postslug
          postslug
        }
        title
        slug
        content
      }
    }
  }
  ${HOMEPAGE_METATAGS_FRAGMENT}
`;

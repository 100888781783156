import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Flex from '../../../styles/Flex';
import Text from '../../../styles/Text';
import Styles from './styles';
import { useMediaUrl } from '../../../utils/mediaUtils';
import Time from '../../Icon/16x16/Time';
import ArrowRight from '../../Icon/16x16/ArrowRight';

interface SmallCardProps {
  image?: string | null;
  title: string;
  content: string;
}

const SmallCard: React.FC<SmallCardProps> = ({ image, title, content }) => {
  const imagePath = useMediaUrl(image);

  const shortContent = useMemo(() => {
    // Removes HTML tags from text
    let shortContent = content.replace(/<\/?[^>]+(>|$)/g, '');
    if (content.length > 150) {
      // Maximum length for content in card is 150 characters
      shortContent = shortContent.slice(0, 150).trim();
      // Removing final . if present
      shortContent = shortContent[148] === '.' ? shortContent.slice(0, 148).trim() + '...' : shortContent + '...';
    }
    return shortContent;
  }, [content]);

  const readingMinutes = useMemo(() => Math.ceil(content.split(' ').length / 200), [content]);

  return (
    <Styles.Card variant="card" hoverVariant="card-hover">
      <Styles.Image src={imagePath || '/static/img/blog_post_image.png'} />
      <Styles.ContentContainer vertical marginHorizontal="sp24" marginVertical="sp32" paddingBottom="sp24" fullHeight>
        <Text.H4 color="secondary">{title}</Text.H4>
        <Text.SmallParagraph color="paragraphLight1" marginTop="sp16" marginBottom="sp24">
          {shortContent}
        </Text.SmallParagraph>
        <Styles.BottomContainer justifyContent="space-between">
          <Flex>
            <Text.SmallerParagraph color="primary" marginRight="sp4">
              <FormattedMessage id="blog.cardReadMore" />
            </Text.SmallerParagraph>
            <ArrowRight color="primary" />
          </Flex>
          <Flex>
            <Time marginRight="sp4" color="paragraphLight1" />
            <Text.SmallerParagraph color="paragraphLight1">
              <FormattedMessage id="blog.cardReadTime" values={{ time: readingMinutes }} />
            </Text.SmallerParagraph>
          </Flex>
        </Styles.BottomContainer>
      </Styles.ContentContainer>
    </Styles.Card>
  );
};

export default SmallCard;

import React, { useState } from 'react';
import Flex from '../../../styles/Flex';
import Text from '../../../styles/Text';
import Styles from './styles';
import SvgLess from '../../Icon/24x24/Less';
import SvgPlus from '../../Icon/24x24/Plus';
import HtmlParsedContent from '../../HtmlParsedContent';

interface FAQCardProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  overrideExpandedMobileUp?: boolean;
}

const FAQCard: React.FC<FAQCardProps> = ({ title, content, overrideExpandedMobileUp }) => {
  const [expandDescription, setExpandDescription] = useState(false);

  return (
    <>
      <Styles.Card hoverVariant="card-hover" overrideExpandedMobileUp={overrideExpandedMobileUp}>
        <Flex alignItems="center">
          <Styles.Line />
          <Flex full justifyContent="space-between">
            {expandDescription ? (
              <a onClick={() => setExpandDescription(false)} className="containerLink">
                {typeof title === 'string' ? <Text.H5 marginBottom="sp16">{title}</Text.H5> : title}
                <Styles.ExpandIconContainer overrideExpandedMobileUp={overrideExpandedMobileUp}>
                  <SvgLess color="primary" />
                </Styles.ExpandIconContainer>
              </a>
            ) : (
              <a onClick={() => setExpandDescription(true)} className="containerLink">
                {typeof title === 'string' ? <Text.H5 marginBottom="sp16">{title}</Text.H5> : title}
                <Styles.ExpandIconContainer overrideExpandedMobileUp={overrideExpandedMobileUp}>
                  <SvgPlus color="primary" />
                </Styles.ExpandIconContainer>
              </a>
            )}
          </Flex>
        </Flex>

        <Styles.BottomContainer
          overrideExpandedMobileUp={overrideExpandedMobileUp}
          expandDescription={expandDescription}
          className="faq__answer"
        >
          {typeof content === 'string' ? <HtmlParsedContent content={content} /> : content}
        </Styles.BottomContainer>
      </Styles.Card>
    </>
  );
};

export default FAQCard;

import Router from 'next/router';
import qs from 'qs';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/styles/Button';
import Container from '../../../styles/Container';
import Input from '../../../styles/Input';
import { useLocale } from '../../Providers/localeProvider';
import TitleAndDescription from '../../TitleAndDescription';
import HeroStyles from './styles';

type HomeHeroProps = { title?: string | null };

const HomeHero: React.FC<HomeHeroProps> = ({ title }) => {
  const locale = useLocale();
  const inputRef = useRef<null | HTMLInputElement>(null);

  const onSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (inputRef && inputRef.current) {
        const search = `${qs.stringify(
          { query: inputRef.current.value || undefined },
          {
            addQueryPrefix: true,
            arrayFormat: 'repeat'
          }
        )}`;
        Router.push(`/[locale]/search${search}`, `/${locale}/search${search}`);
      }
    },
    [locale]
  );

  return (
    <Container>
      <TitleAndDescription title={title} color="paragraphOnInverted">
        <form onSubmit={onSearch}>
          <HeroStyles.FormWrapper>
            <HeroStyles.DropdownWrapper>
              <Input.Text ref={inputRef} placeholder="Search in all our categories" />
            </HeroStyles.DropdownWrapper>
            <Button.xLarge type="submit">
              <FormattedMessage id="home.exportConnect.searchBoxButton" />
            </Button.xLarge>
          </HeroStyles.FormWrapper>
        </form>
      </TitleAndDescription>
    </Container>
  );
};

export default HomeHero;

import React from 'react';
import HomeCategoriesStyles from './styles';
import Link from 'next/link';

//styles
import Text from 'src/styles/Text';

//components
import Icons from 'src/components/Icon/Categories';
import ArrowRight from 'src/components/Icon/16x16/ArrowRight';

//apollo
import { FormattedMessage } from 'react-intl';
import { useLocale } from '../../Providers/localeProvider';
import Animations from '../../../styles/Animations';
import { GetMetadataDetailsQuery } from '../../../typings/generated/backendGraphql';

const animationSeconds = 0.3;
const animationDelayMultiplier = 0.12;

// For now using "GetNavbarDetailsQuery" since that's exactly where the data is coming from for the home page.
// If more properties are needed or the data comes from a different place then it's time to create a fragment to get its type for this component
type HomeCategoriesProps = { serviceCategories?: GetMetadataDetailsQuery['metadataServiceCategories'] };

const HomeCategories: React.FC<HomeCategoriesProps> = ({ serviceCategories }) => {
  const locale = useLocale();
  return (
    <>
      <Animations.FadeIn delaySeconds={0.7}>
        <HomeCategoriesStyles.Title>
          <FormattedMessage id="home.exportConnect.categoryHeader" />
        </HomeCategoriesStyles.Title>
      </Animations.FadeIn>
      <Animations.FadeIn delaySeconds={0.7}>
        <HomeCategoriesStyles.Wrapper variant="card">
          {serviceCategories &&
            serviceCategories.length > 0 &&
            serviceCategories.map((serviceCategory, index) => {
              const SelectedIcon = Icons(serviceCategory.slug);
              return (
                <Animations.FadeUp
                  key={serviceCategory.slug}
                  seconds={animationSeconds}
                  delaySeconds={index * animationDelayMultiplier + 0.72}
                  fullWidth
                  fullHeight
                >
                  <Link
                    href="/[locale]/category/[...categoriesSlugs]"
                    as={`/${locale}/category/${serviceCategory.slug}`}
                  >
                    <a style={{ textDecoration: 'none', color: 'inherit' }}>
                      <HomeCategoriesStyles.Item alignItems="flex-start">
                        <SelectedIcon />
                        <HomeCategoriesStyles.Description vertical>
                          <Text.H5>{serviceCategory.name}</Text.H5>
                          <Text.SmallParagraph>{serviceCategory.description}</Text.SmallParagraph>
                        </HomeCategoriesStyles.Description>
                      </HomeCategoriesStyles.Item>
                      <HomeCategoriesStyles.CategoryLink className="categoryLink">
                        <Text.SmallerParagraph color="primary" weight={900}>
                          <FormattedMessage id="home.exportConnect.categoriesViewMoreButton" />{' '}
                        </Text.SmallerParagraph>
                        <ArrowRight width="14" color="primary"></ArrowRight>
                      </HomeCategoriesStyles.CategoryLink>
                    </a>
                  </Link>
                </Animations.FadeUp>
              );
            })}
        </HomeCategoriesStyles.Wrapper>
      </Animations.FadeIn>

      {/* <HomeCategoriesStyles.Button>
        <FormattedMessage id="home.exportConnect.allCategoriesButton" />
      </HomeCategoriesStyles.Button> */}
    </>
  );
};
export default HomeCategories;

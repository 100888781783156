import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgLess(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12a1 1 0 01-1 1H4a1 1 0 010-2h16a1 1 0 011 1z" className="fillSecondary" />
      </svg>
    </IconStyles.Container>
  );
}

export default SvgLess;

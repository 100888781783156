import styled from 'styled-components';
import ResponsiveMediaQuery from '../../../styles/ResponsiveMediaQuery';
import Tile, { TileProps } from '../../../styles/Tile';

export default {
  Line: styled.span`
    width: 6px;
    height: 67px;
    background-color: ${(props) => props.theme.colors.primary};
    margin-right: ${(props) => props.theme.spacing.sp24};
  `,

  Card: styled(Tile)<TileProps & { overrideExpandedMobileUp?: boolean }>`
    transition: transform 60ms, box-shadow 0.2s ease;
    transform: translateY(0px);
    &:active,
    &:focus {
      transition: box-shadow 0.03s ease;
      transform: translateY(3px);
      ${ResponsiveMediaQuery('mobile')} {
        ${(props) =>
          props.overrideExpandedMobileUp
            ? ` transition: none;
                transform: none;`
            : ``}
      }
    }

    -webkit-box-shadow: 10px 15px 80px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 15px 80px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 15px 80px 0px rgba(0, 0, 0, 0.1);

    .containerLink {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: ${(props) => props.theme.spacing.sp16};

      h5 {
        margin-bottom: 0;

        ::first-letter {
          text-transform: uppercase;
        }
      }

      ${ResponsiveMediaQuery('mobile')} {
        ${(props) => (props.overrideExpandedMobileUp ? `cursor: default;` : ``)}
      }
    }
  `,

  ExpandIconContainer: styled.div<{ overrideExpandedMobileUp?: boolean }>`
    ${ResponsiveMediaQuery('mobile')} {
      ${(props) => (props.overrideExpandedMobileUp ? `display: none` : ``)}
    }
  `,

  Collapse: styled.div<{ expandDescription: boolean }>`
    ${(props) =>
      !props.expandDescription
        ? `
      height: 280px;
      overflow: hidden; 
      `
        : ``}
  `,

  BottomContainer: styled.div<{ expandDescription?: boolean; overrideExpandedMobileUp?: boolean }>`
    padding-left: calc(6px + (${(props) => props.theme.spacing.sp24}));

    ${(props) => (props.expandDescription ? `` : `display: none;`)}

    ${ResponsiveMediaQuery('mobile')} {
      ${(props) => (props.overrideExpandedMobileUp ? `display: block` : ``)}
    }
  `
};

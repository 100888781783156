import Flex from 'src/styles/Flex';
import styled from 'styled-components';

export default {
  Title: styled(Flex)`
    width: 100%;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacing.sp72} 0 ${(props) => props.theme.spacing.sp32};
  `,

  ArticlesLink: styled(Flex)`
    height: 38px;
    align-items: flex-end;

    p {
      margin-right: ${(props) => props.theme.spacing.sp8};
      line-height: 20px;
    }
  `
};

import React from 'react';
import FilteredImageBackgroundStyles from './styles';

export type FilteredImageBackgroundProps = {
  imageSrc?: string | null;
  positionHorizontal?: 'left' | 'center' | 'right' | undefined;
  positionVertical?: 'top' | 'center' | 'bottom' | undefined;
  gradientFilter?: boolean;
};

const FilteredImageBackground: React.FC<FilteredImageBackgroundProps> = ({ children, ...props }) => {
  return (
    <FilteredImageBackgroundStyles.Container {...props}>
      <FilteredImageBackgroundStyles.Content>{children}</FilteredImageBackgroundStyles.Content>
    </FilteredImageBackgroundStyles.Container>
  );
};

export default FilteredImageBackground;

import Flex from 'src/styles/Flex';
import styled from 'styled-components';
import ResponsiveMediaQuery from '../../../styles/ResponsiveMediaQuery';

export default {
  Wrapper: styled(Flex)`
    justify-content: space-between;
    margin: ${(props) => props.theme.spacing.sp48} auto ${(props) => props.theme.spacing.sp48};
    display: none;
    max-width: 100%;

    ${ResponsiveMediaQuery('mobile')} {
      display: flex;
    }

    ${ResponsiveMediaQuery('tablet')} {
      max-width: 70%;
    }
  `,

  Item: styled(Flex)`
    justify-content: space-between;
    align-items: center;
    text-align: center;

    svg,
    img {
      width: 60px;
      height: 60px;
    }

    p {
      max-width: 170px;
      line-height: 22px;

      ${ResponsiveMediaQuery('tablet')} {
        max-width: 250px;
      }
    }
  `
};

import React, { useMemo } from 'react';
import ImageHeroLayout from '../../../../elements/ImageHeroLayout';
import Container from '../../../../styles/Container';
import { GetMetadataDetailsQuery } from '../../../../typings/generated/backendGraphql';
import { GetCmsExportConnectHomepageQuery } from '../../../../typings/generated/cmsGraphql';
import { useMediaUrl } from '../../../../utils/mediaUtils';
import LatestPosts from '../../../Blog/LatestPost';
import CMSMetatags from '../../../CMSMetatags';
import Badge from '../../../Home/Badge';
import Categories from '../../../Home/Categories';
import Faq from '../../../Home/FAQ';
import HomeHero from '../../../Home/Hero';
import Newsletter, { NewsletterProps } from '../../../Home/Newsletter';

type ExportConnectHomeTemplateProps = {
  loading?: boolean;
  metaData?: GetMetadataDetailsQuery;
  cmsData?: GetCmsExportConnectHomepageQuery;
  formNewsletter: NewsletterProps;
};

const ExportConnectHomeTemplate: React.FC<ExportConnectHomeTemplateProps> = ({ metaData, cmsData, formNewsletter }) => {
  const cmsObject = useMemo(
    () =>
      cmsData && cmsData.homePages && cmsData.homePages.nodes && cmsData.homePages.nodes.length > 0
        ? cmsData.homePages.nodes[0]
        : undefined,
    [cmsData]
  );

  const imageSrc = useMediaUrl(cmsObject?.header?.headerimage?.mediaItemUrl);

  return (
    <ImageHeroLayout imageSrc={imageSrc} heroContent={() => <HomeHero title={cmsObject?.header?.headertitle} />}>
      <CMSMetatags title={cmsObject?.title} metaTags={cmsObject?.metaTags} />
      <Container>
        <Badge stats={cmsObject?.extraContent?.statssection} />
        {metaData?.metadataServiceCategories && metaData?.metadataServiceCategories.length > 0 && (
          <Categories serviceCategories={metaData?.metadataServiceCategories} />
        )}
      </Container>
      <Faq faqs={cmsData?.faqs}></Faq>
      <Container>
        <LatestPosts posts={cmsData?.posts}></LatestPosts>
      </Container>
      <Newsletter {...formNewsletter} />
    </ImageHeroLayout>
  );
};

export default ExportConnectHomeTemplate;

import Flex from 'src/styles/Flex';
import Text from 'src/styles/Text';
import styled from 'styled-components';
import ResponsiveMediaQuery from '../../../styles/ResponsiveMediaQuery';

export default {
  FormWrapper: styled(Flex)`
    border-radius: 10px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    max-width: 80%;

    ${ResponsiveMediaQuery('tablet')} {
      background: ${(props) => props.theme.colors.background};
      flex-direction: row;
      max-width: 500px;
      padding: 10px 10px 0 10px;
    }

    button {
      max-width: 100%;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
  `,

  DropdownWrapper: styled(Flex)`
    width: 100%;
    justify-content: center;
    flex-direction: column;
    background: ${(props) => props.theme.colors.background};
    border-radius: 10px;
    padding: 0 5%;
    margin-bottom: 20px;
    margin-right: 0;

    ${ResponsiveMediaQuery('tablet')} {
      margin-bottom: 0;
      padding: 0 0 13px;
    }

    &:hover ul {
      display: block;
    }

    input {
      text-overflow: ellipsis;
      border: none;
      box-shadow: none;
      outline: none;
      text-align: center;

      ${ResponsiveMediaQuery('tablet')} {
        text-align: left;
      }
    }
  `,

  Label: styled(Text.SmallerParagraph)`
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 4px;
    top: 10px;
    left: 20px;
  `,

  Dropdown: styled.div`
    position: relative;
    z-index: 2;

    &:hover ul {
      display: block;
    }

    h5 {
      display: flex;
      justify-content: space-between;
      width: 97%;
      padding-right: 3%;
      align-items: center;
      position: relative;

      svg {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -8px;
        width: 24px;
        height: 24px;

        ${ResponsiveMediaQuery('tablet')} {
          right: 15px;
        }
      }
    }
    ul {
      position: absolute;
      left: -20px;
      top: 100%;
      width: calc(100% - 20px);
      background: ${(props) => props.theme.colors.background};
      border-radius: 0 0 10px 10px;
      padding: 30px 20px;
      border: 1px solid ${(props) => props.theme.colors.borderLight1};
      border-top: none;
      display: none;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        background: ${(props) => props.theme.colors.background};
        top: -10px;
        left: -1px;
      }

      li {
        list-style: none;
        margin-bottom: 10px;
        font-size: 18px;

        &:hover {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  `
};

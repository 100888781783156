import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Error from 'next/error';
import { useRouter } from 'next/router';
import React from 'react';
import { NormalizedCacheObject } from 'react-apollo';
import { initializeApollo } from '../../../apollo';
import { GET_CMS_EXPORT_CONNECT_HOMEPAGE } from '../../../apollo/modules/cms/home';
import { GET_METADATA_DETAILS } from '../../../apollo/modules/metadata';
import { SEND_NEWSLETTER_FORM } from '../../../apollo/modules/home';
import ExportConnectHomeTemplate from '../../../components/Templates/Home/ExportConnect';
import env from '../../../env';
import {
  GetMetadataDetailsQuery,
  GetMetadataDetailsQueryVariables,
  SendNewsletterFormMutation,
  SendNewsletterFormMutationVariables
} from '../../../typings/generated/backendGraphql';
import {
  GetCmsExportConnectHomepageQuery,
  GetCmsExportConnectHomepageQueryVariables
} from '../../../typings/generated/cmsGraphql';
import logging from '../../../utils/loggingUtils';
import useForm from '../../../utils/formUtils';
import { defaultLocale } from '../../../../locales';

type ExportConnectHomeElementGetStaticProps = {
  // Params
  locale?: string;

  // Props
  errorStatus?: number;
  metaData?: GetMetadataDetailsQuery;
  cmsData?: GetCmsExportConnectHomepageQuery;

  // Rehydration
  initialApolloState?: NormalizedCacheObject;
};

type ExportConnectHomeElementProps = InferGetStaticPropsType<typeof exportConnectGetStaticProps>;

const ExportConnectHomeElement: React.FC<ExportConnectHomeElementProps> = ({ errorStatus, metaData, cmsData }) => {
  const router = useRouter();
  const formNewsletter = useForm<SendNewsletterFormMutation, SendNewsletterFormMutationVariables>(SEND_NEWSLETTER_FORM);

  if (errorStatus) return <Error statusCode={errorStatus} />;

  return (
    <ExportConnectHomeTemplate
      cmsData={cmsData}
      formNewsletter={formNewsletter}
      metaData={metaData}
      loading={router.isFallback}
    />
  );
};

export const exportConnectGetStaticProps: GetStaticProps<ExportConnectHomeElementGetStaticProps> = async ({
  params
}) => {
  const props: ExportConnectHomeElementGetStaticProps = { ...params };

  const apolloClient = initializeApollo();

  try {
    if (params && params.locale) {
      const getCmsExportConnectHomepageData = async (languageCode: string) => {
        return apolloClient.query<GetCmsExportConnectHomepageQuery, GetCmsExportConnectHomepageQueryVariables>({
          query: GET_CMS_EXPORT_CONNECT_HOMEPAGE,
          variables: { languageCode, postSlug: 'home' },
          context: { endpoint: 'cms' } // Important! Queries in the crm need this
        });
      };
      let { data: cmsData } = await getCmsExportConnectHomepageData(params.locale as string);
      if (!cmsData?.homePages?.nodes?.length)
        ({ data: cmsData } = await getCmsExportConnectHomepageData(defaultLocale));
      props.cmsData = cmsData;

      const { data: metaData } = await apolloClient.query<GetMetadataDetailsQuery, GetMetadataDetailsQueryVariables>({
        query: GET_METADATA_DETAILS
      });
      props.metaData = metaData;

      // if(errors && errors.length > 0) {
      //   props.errorStatus = errors[0].
      // }

      if (!cmsData?.homePages?.nodes || cmsData.homePages.nodes.length < 1) {
        props.errorStatus = 404;
      }
    } else {
      props.errorStatus = 404;
    }
  } catch (error) {
    logging.error(error, `Failed to load static props for [ExportConnectHomeElement]`, { params });
    // props.errorStatus = 500;
  }

  props.initialApolloState = apolloClient.cache.extract();

  return {
    props,
    // we will attempt to re-generate the page:
    // - when a request comes in
    // - at most once every X seconds
    revalidate: env().REVALIDATE_SECONDS
  };
};

export default ExportConnectHomeElement;

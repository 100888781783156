import React from 'react';
import FaqStyles from './styles';
import Link from 'next/link';

//styles
import Text from 'src/styles/Text';
import Container from 'src/styles/Container';

//components
import ArrowRight from 'src/components/Icon/16x16/ArrowRight';
import FAQCard from 'src/components/FAQ/FAQCard';

//apollo
import { FormattedMessage } from 'react-intl';
import { useLocale } from '../../Providers/localeProvider';
import { GetCmsExportConnectHomepageQuery } from '../../../typings/generated/cmsGraphql';

// If more properties are needed or the data comes from a different place then it's time to create a fragment to get its type for this component
type FaqsProps = { faqs?: GetCmsExportConnectHomepageQuery['faqs'] };

const Faq: React.FC<FaqsProps> = ({ faqs }) => {
  const locale = useLocale();

  return (
    <FaqStyles.Wrapper>
      <Container className="faqContainer">
        <FaqStyles.LeftColumn>
          <FaqStyles.Subtitle>
            <FormattedMessage id="home.exportConnect.faqSubtitle" />
          </FaqStyles.Subtitle>
          <FaqStyles.Title>
            <FormattedMessage id="home.exportConnect.faqTitle" />
          </FaqStyles.Title>
          <FaqStyles.FlavorText>
            <FormattedMessage id="home.exportConnect.faqFlavorText" />
          </FaqStyles.FlavorText>
          <Link href="/[locale]/faq" as={`/${locale}/faq`}>
            <a style={{ textDecoration: 'none', color: 'primary' }}>
              <FaqStyles.ArticlesLink className="categoryLink">
                <Text.Paragraph color="primary" weight={900}>
                  <FormattedMessage id="home.exportConnect.faqLink" />
                </Text.Paragraph>
                <ArrowRight width="14" color="primary"></ArrowRight>
              </FaqStyles.ArticlesLink>
            </a>
          </Link>
        </FaqStyles.LeftColumn>
        <FaqStyles.Grid>
          {faqs &&
            faqs?.nodes?.map((faq, index) => {
              return (
                <div className="item" key={index}>
                  <FAQCard title={faq?.title as string} content={faq?.content as string} />
                </div>
              );
            })}
        </FaqStyles.Grid>
      </Container>
    </FaqStyles.Wrapper>
  );
};

export default Faq;

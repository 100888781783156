import React from 'react';
import Animations from '../../styles/Animations';
import Spacing from '../../styles/Spacing';
import Text from '../../styles/Text';
import { AppTheme } from '../../styles/Theme';
import TitleAndDescriptionStyles from './styles';

type TitleAndDescriptionProps = {
  title?: string | React.ReactNode | null;
  description?: string | React.ReactNode | null;
  color?: keyof AppTheme['colors'];
};

const TitleAndDescription: React.FC<TitleAndDescriptionProps> = ({ title, description, children, color }) => {
  return (
    <Spacing paddingVertical="sp96">
      <Animations.FadeUp>
        {title && (
          <TitleAndDescriptionStyles.Title color={color} align="center">
            {title}
          </TitleAndDescriptionStyles.Title>
        )}
      </Animations.FadeUp>

      <Animations.FadeIn delaySeconds={title ? 0.3 : 0}>
        {description && (
          <Text.Paragraph color={color} align="center" marginTop="sp42">
            {description}
          </Text.Paragraph>
        )}
        {children}
      </Animations.FadeIn>
    </Spacing>
  );
};

export default TitleAndDescription;

import styled from 'styled-components';
import Text from '../../styles/Text';

export default {
  Title: styled(Text.MediumTitle)`
    margin: 0 auto;
    margin-bottom: ${(props) => props.theme.spacing.sp72};
    width: 80%;
  `
};

import styled from 'styled-components';
import Tile from '../../../styles/Tile';
import Flex from '../../../styles/Flex';

export default {
  Card: styled(Tile)`
    transition: transform 60ms, box-shadow 0.2s ease;
    transform: translateY(0px);
    &:active,
    &:focus {
      transition: box-shadow 0.03s ease;
      transform: translateY(3px);
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  `,

  Image: styled.img`
    width: 100%;
    min-height: 256px;
    max-height: 256px;
    object-fit: cover;
  `,

  ContentContainer: styled(Flex)`
    position: relative;
  `,

  BottomContainer: styled(Flex)`
    width: 100%;
    position: absolute;
    bottom: 0;
  `
};

import React from 'react';
import Text from 'src/styles/Text';
import Animations from '../../../styles/Animations';
import { useMediaUrl } from '../../../utils/mediaUtils';
import BadgeStyles from './styles';
import { GetCmsExportConnectHomepageQuery } from '../../../typings/generated/cmsGraphql';

const animationSeconds = 0.6;
const animationDelayMultiplier = 0.12;

type BadgeProps = {
  stats?: NonNullable<
    NonNullable<
      NonNullable<NonNullable<GetCmsExportConnectHomepageQuery['homePages']>['nodes']>[number]
    >['extraContent']
  >['statssection'];
};

type BadgeItemType = NonNullable<BadgeProps['stats']>[number];

const BadgeItem: React.FC<BadgeItemType & { index: number }> = ({ text, title, icon, index }) => {
  const statIcon = useMediaUrl(icon?.mediaItemUrl);
  return (
    <Animations.FadeUp seconds={animationSeconds} delaySeconds={index * animationDelayMultiplier} fullWidth fullHeight>
      <BadgeStyles.Item key={title as string} vertical>
        <img src={statIcon} alt={title as string} />
        <Text.H2>{title}</Text.H2>
        <Text.Paragraph>{text}</Text.Paragraph>
      </BadgeStyles.Item>
    </Animations.FadeUp>
  );
};

const Badge: React.FC<BadgeProps> = ({ stats }) => {
  return (
    <BadgeStyles.Wrapper>
      {stats &&
        stats.length > 0 &&
        stats
          .filter((x) => !!x) // Filter empty or null values
          .map((stat, index) => (
            <BadgeItem
              key={(stat as NonNullable<BadgeItemType>).title as string}
              index={index}
              {...(stat as NonNullable<BadgeItemType>)}
            />
          ))}
    </BadgeStyles.Wrapper>
  );
};
export default Badge;

import styled from 'styled-components';
import helpers from '../helpers';
import ResponsiveMediaQuery from '../ResponsiveMediaQuery';

const Grid = styled.div<{ gapSize?: string; columns?: number }>`
  /* Mobile */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${(props) => props.gapSize || '24px'};
  grid-row-gap: ${(props) => props.gapSize || '24px'};
  margin: 0 auto;

  /* Tablet(768+) */
  ${ResponsiveMediaQuery('tablet')} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${(props) => props.gapSize || '32px'};
    grid-row-gap: ${(props) => props.gapSize || '32px'};
  }

  /* Laptop(1025+) */
  ${ResponsiveMediaQuery('laptop')} {
    ${(props) => (props.columns ? `grid-template-columns: repeat(${props.columns}, 1fr);` : ``)}
    grid-column-gap: ${(props) => props.gapSize || '28px'};
    grid-row-gap: ${(props) => props.gapSize || '28px'};
  }

  /* Desktop(1440+) */
  ${ResponsiveMediaQuery('desktop')} {
    grid-column-gap: ${(props) => props.gapSize || '36px'};
    grid-row-gap: ${(props) => props.gapSize || '36px'};
  }

  ${helpers};
`;

export default Grid;

import styled, { css } from 'styled-components';
import { FilteredImageBackgroundProps } from './';

export default {
  Container: styled.div.attrs({ className: 'relative z-10' })<FilteredImageBackgroundProps>`
    /* className sets up positioning so the image and the content can stay on top of this */
    /* className z index necessary to allow map to take full sreen, but also allow drawer to be on top of content on mobile */
    ${(props) => props.gradientFilter && `background: linear-gradient(rgb(244, 245, 245), rgba(246, 246, 246, 0));`}

    ${(props) =>
      props.imageSrc && // If there's an image
      css`
        /* The background image */
        &:before {
          /* Required for the "div" to be displayed */
          content: '';
          display: block;

          /* Puts this in front */
          position: absolute;
          z-index: 2;

          /* The image size should take 100% of the Container since it's a "background" */
          height: 100%;
          width: 100%;

          /* Adds the background image itself */
          background: url(${props.imageSrc}) ${props.positionHorizontal || 'center'}
            ${props.positionVertical || 'center'} / cover no-repeat;

          /* Adds the filter */
          filter: ${props.gradientFilter ? 'none' : 'brightness(60%)'};
          /* background-color: rgba(0, 0, 0, 0.6); */
        }
      `}
  `,
  Content: styled.div`
    /* We need to set the position property to use z-index */
    position: inherit;
    /* Puts it in front of the image */
    z-index: 3;

    /* It takes 100% */
    height: 100%;
    width: 100%;
  `
};

import Flex from 'src/styles/Flex';
import styled from 'styled-components';
import ResponsiveMediaQuery from '../../../styles/ResponsiveMediaQuery';

export default {
  Wrapper: styled(Flex)`
    justify-content: center;
    padding: ${(props) => props.theme.spacing.sp48} 0 ${(props) => props.theme.spacing.sp48};
    max-width: 100%;
    align-items: center;
    text-align: center;
    background: ${(props) => props.theme.colors.secondary};
    opacity: 0.98;
    margin-bottom: -100px;

    ${ResponsiveMediaQuery('mobile')} {
      display: flex;
    }
  `,

  Title: styled(Flex)``,

  Form: styled(Flex)`
    background: ${(props) => props.theme.colors.background};
    padding: 10px;
    justify-content: space-between;
    align-items: center;

    input {
      width: 100%;
      height: 35px;
      margin: 0 8px;
      border: none;
      font-size: 16px;
      outline: none;
    }

    button {
      width: 35px;
      height: 35px;
      border: none;
      outline: none;
      background: ${(props) => props.theme.colors.background};
      cursor: pointer;
      :disabled {
        cursor: default;
      }
    }
  `
};

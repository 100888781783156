import React from 'react';
import FilteredImageBackground from '../../styles/FilteredImageBackground';
import AppLayout from '../AppLayout';
import FooterElement from '../Footer';
import NavbarElement from '../Navbar';

type ImageHeroLayoutProps = {
  imageSrc?: string | null;
  heroContent?: () => React.ReactNode;
  noFooterMargin?: boolean;
};

/**
 * A layout with a hero that contains a filtered background image
 * and that the navbar is also contained in it
 */
const ImageHeroLayout: React.FC<ImageHeroLayoutProps> = ({ imageSrc, heroContent, children, noFooterMargin }) => {
  return (
    <AppLayout>
      <div id="image-hero-layout">
        <header>
          <FilteredImageBackground imageSrc={imageSrc}>
            <NavbarElement transparentHeader={true} />
            {heroContent && heroContent()}
          </FilteredImageBackground>
        </header>
        <section>{children}</section>
        <FooterElement noMarginTop={noFooterMargin} />
      </div>
    </AppLayout>
  );
};

export default ImageHeroLayout;

import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import locales, { defaultLocale } from '../../locales';
import { apolloHOC } from '../../src/apollo';
import { CLIENT_IDS } from '../../src/constraints';
import env from '../../src/env';
import ExportConnectHomeElement, { exportConnectGetStaticProps } from '../../src/elements/Home/ExportConnect';
import { NormalizedCacheObject } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../src/components/Spinner';
import Flex from '../../src/styles/Flex';
import Spacing from '../../src/styles/Spacing';
import Text from '../../src/styles/Text';

type HomeContainerGetStaticProps = { initialApolloState?: NormalizedCacheObject; locale?: string };

type HomeContainerProps = InferGetStaticPropsType<typeof getStaticProps>;

const HomeContainer: NextPage<HomeContainerProps> = (props) => {
  const router = useRouter();
  const clientId = useMemo(() => env().NEXT_PUBLIC_CLIENT_ID || 'no-client', []);

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback) {
    return (
      <Flex vertical full alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
        <div>
          <Spinner.Default size="3em" color="primary" />
          <Spacing marginTop="sp24">
            <Text.Paragraph align="center">
              <FormattedMessage id="loading" />
            </Text.Paragraph>
          </Spacing>
        </div>
      </Flex>
    );
  }

  switch (clientId) {
    case CLIENT_IDS.CALIFORNIA_CONNECT:
    // return <CaliforniaConnectHomeElement {...props} />;
    default:
      return <ExportConnectHomeElement {...props} />;
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    // For the homepage, map the locales to possible params
    paths: [...Object.keys(locales).map((locale) => ({ params: { locale } }))],
    fallback: true
  };
};

export const getStaticProps: GetStaticProps<HomeContainerGetStaticProps> = async (context) => {
  if (context.params?.locale === 'migrate')
    // Redirect https://export-connect.org/migrate to https://export-connect.org/en/migrate (or equivalent environment)
    return {
      redirect: {
        destination: `/${defaultLocale}/migrate`,
        permanent: true
      },
      // we will attempt to re-generate the page:
      // - when a request comes in
      // - at most once every X seconds
      revalidate: env().REVALIDATE_SECONDS
    };

  const clientId = env().NEXT_PUBLIC_CLIENT_ID || 'no-client';

  switch (clientId) {
    case CLIENT_IDS.CALIFORNIA_CONNECT:
    default: {
      const props = await exportConnectGetStaticProps(context);
      if (props) {
        return props;
      }
    }
  }

  return {
    props: context.params ? { ...context.params } : {},
    // we will attempt to re-generate the page:
    // - when a request comes in
    // - at most once every X seconds
    revalidate: env().REVALIDATE_SECONDS
  };
};

export default apolloHOC(HomeContainer);

import { useCallback, useMemo, useState } from 'react';
import { initializeApollo } from '../apollo';
import { OperationVariables } from '@apollo/client/core/types';
import { DocumentNode } from '@apollo/client/core';
import { useRouter } from 'next/router';
import { useFetchApi } from './fetchUtils';
import { getLocaleIdFromFetchError, getLocaleIdFromGraphqlError } from './exceptionUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useForm = <T = any, TVariables = OperationVariables>(
  mutation: DocumentNode
): {
  formSubmit: (variables: TVariables, setSubmitting: (isSubmitting: boolean) => void) => void;
  formSent: boolean;
  formError?: { message?: string };
} => {
  const [formSent, setFormSent] = useState(false);
  const [formError, setFormError] = useState<{ message?: string } | undefined>(undefined);

  const formSubmit = useCallback(
    async (variables: TVariables, setSubmitting: (isSubmitting: boolean) => void) => {
      try {
        setFormError(undefined);
        const apolloClient = initializeApollo();
        const { data: success, errors } = await apolloClient.mutate<T, TVariables>({
          mutation,
          variables: { ...variables }
        });
        if (success || !errors) setFormSent(true);
        else {
          console.log('errors', errors);
          setFormError(errors?.length ? { message: errors[0].message } : {});
          setSubmitting(false);
        }
      } catch (error) {
        console.log('error', error);
        setFormError({ message: error.message });
        setSubmitting(false);
      }
    },
    [mutation]
  );
  return { formSubmit, formSent, formError };
};

type GraphqlFormModalHookOptions<
  T extends Record<string, unknown> | Record<string, unknown>[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends any
> = {
  onSubmit: (form: T) => Promise<{ error?: string; value?: R }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onToggle?: (prev: boolean, next: boolean, ...args: any[]) => void;
};

export const useGraphqlFormModal = <
  T extends Record<string, unknown> | Record<string, unknown>[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends any
>({
  onSubmit: optionsOnSubmit,
  onToggle
}: GraphqlFormModalHookOptions<T, R>) => {
  const router = useRouter();
  const userForceRefresh = useFetchApi('/api/user', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'force-refresh': 'true' }
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) => {
      // Clean the error
      setError('');

      if (onToggle) onToggle(isModalOpen, !isModalOpen, ...args);

      setIsModalOpen(!isModalOpen);
    },
    [isModalOpen, onToggle]
  );

  // Make the error state
  const [error, setError] = useState('');
  const clearError = useMemo(
    () => () => {
      setError('');
    },
    [setError]
  );

  // On form submit
  const onSubmit = useCallback(
    async (form: T, isRetry?: boolean) => {
      // Clean the error
      setError('');
      try {
        const { error, value } = await optionsOnSubmit(form);

        if (!error) {
          // Close the modal
          toggleModal();
        } else {
          setError(error);
        }

        return value;
      } catch (err) {
        // If failed we'll get here
        // Get the locale id

        if (err.data) {
          // This error was thown by a RESTful route call
          const error = await getLocaleIdFromFetchError(err);

          // Update the error state
          if (error) {
            setError(error);
          }
          return undefined;
        }

        const { graphQLErrors, networkError } = err;
        if (graphQLErrors && graphQLErrors.length && graphQLErrors[0].message === 'unauthorized') {
          try {
            // This call will throw if the user is not logged in anymore
            await userForceRefresh();
            if (!isRetry) onSubmit(form, true);
          } catch (error) {
            // User is unauthorized
            // Log out the user
            router.push('/logout', '/logout');
          }
        }
        // If failed we'll get here
        // Get the locale id
        const error = getLocaleIdFromGraphqlError(graphQLErrors, networkError);

        // Update the error state
        if (error) {
          setError(error);
        }

        return undefined;
      }
    },
    [optionsOnSubmit, toggleModal, userForceRefresh, router]
  );

  return { onSubmit, error, toggleModal, isModalOpen, clearError };
};

export default useForm;

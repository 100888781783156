import React from 'react';
import NewsletterStyles from './styles';
import Text from 'src/styles/Text';
import Message from 'src/components/Icon/16x16/Message';
import ArrowRight from 'src/components/Icon/16x16/ArrowRight';
import Container from 'src/styles/Container';
import Animations from '../../../styles/Animations';
import { useIntl, FormattedMessage } from 'react-intl';
import Flex from '../../../styles/Flex';
import { Formik, Form, Field } from 'formik';

export type NewsletterProps = {
  formSubmit: (variables: { email: string }, setSubmitting: (isSubmitting: boolean) => void) => void;
  formSent: boolean;
  formError?: { message?: string };
};

const Newsletter: React.FC<NewsletterProps> = ({ formSubmit, formSent, formError }) => {
  const intl = useIntl();
  return (
    <NewsletterStyles.Wrapper vertical marginTop="sp61">
      <Container>
        <Animations.FadeIn delaySeconds={0.8} fullWidth fullHeight>
          <NewsletterStyles.Title vertical>
            <Text.H2 color="paragraphOnInverted" marginBottom="sp4">
              <FormattedMessage id="home.exportConnect.newsletterTitleLabel" />
            </Text.H2>
            <Text.Paragraph color="paragraphOnInverted" marginBottom="sp32">
              <FormattedMessage id="home.exportConnect.newsletterSubtitleLabel" />
            </Text.Paragraph>
          </NewsletterStyles.Title>
          {formSent ? (
            <Animations.FadeIn>
              <Flex justifyContent="center" fullWidth>
                <Text.H3 color="background">
                  <FormattedMessage id="contact.formMessageSuccess" />
                </Text.H3>
              </Flex>
            </Animations.FadeIn>
          ) : (
            <Formik
              initialValues={{ email: '' }}
              validate={(values) => {
                const errors: { email?: string } = {};
                if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = intl.formatMessage({ id: 'contact.fieldEmailInvalid' });
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                formSubmit({ email: values.email }, setSubmitting);
              }}
            >
              {({ isSubmitting, errors, getFieldMeta, values }) => (
                <Form className="form">
                  <NewsletterStyles.Form>
                    <Message></Message>
                    <Field name="email">
                      {({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        field
                      }) => <input type="text" placeholder="Enter your email address" {...field} />}
                    </Field>
                    <button type="submit" disabled={!values.email || isSubmitting || errors.email !== undefined}>
                      <ArrowRight />
                    </button>
                  </NewsletterStyles.Form>
                  {getFieldMeta('email').touched && getFieldMeta('email').error && (
                    <Animations.FadeDown seconds={0.2}>
                      <Text.SmallerParagraph align="left" color="error" marginTop="sp4">
                        {errors.email}
                      </Text.SmallerParagraph>
                    </Animations.FadeDown>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Animations.FadeIn>
      </Container>
      {formError && (
        <Animations.FadeDown>
          <Flex justifyContent="center" fullWidth marginTop="sp8">
            <Text.Paragraph color="error">
              {formError?.message || intl.formatMessage({ id: 'contact.formMessageError' })}
            </Text.Paragraph>
          </Flex>
        </Animations.FadeDown>
      )}
    </NewsletterStyles.Wrapper>
  );
};
export default Newsletter;

import React from 'react';
import Head from 'next/head';
import { useMediaUrl } from '../../utils/mediaUtils';

type CMSMetatagsProps = {
  title?: string | null;
  metaTags?: {
    title?: string | null;
    description?: string | null;
    image?: { mediaItemUrl?: string | null } | null;
  } | null;
};

const CMSMetatags: React.FC<CMSMetatagsProps> = ({ title, metaTags }) => {
  const metaImage = useMediaUrl(metaTags?.image?.mediaItemUrl);
  return (
    <Head>
      {metaTags?.title ? (
        <>
          <title>{metaTags.title}</title>
          <meta property="og:title" content={metaTags.title} />
        </>
      ) : (
        title && (
          <>
            <title>{title}</title>
            <meta property="og:title" content={title} />
          </>
        )
      )}

      {metaTags?.description && (
        <>
          <meta name="description" content={metaTags.description} />
          <meta property="og:description" content={metaTags.description} />
        </>
      )}

      {metaImage && <meta property="og:image" content={metaImage} />}
    </Head>
  );
};

export default CMSMetatags;

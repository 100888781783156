import Button from 'src/styles/Button';
import Flex from 'src/styles/Flex';
import Text from 'src/styles/Text';
import Tile from 'src/styles/Tile';
import styled from 'styled-components';
import ResponsiveMediaQuery from '../../../styles/ResponsiveMediaQuery';

export default {
  Title: styled(Text.MediumTitle)`
    padding-top: ${(props) => props.theme.spacing.sp48};
    border-top: 1px solid ${(props) => props.theme.colors.borderLight2};
    text-align: center;
  `,

  Wrapper: styled(Tile)`
    justify-content: space-between;
    margin: ${(props) => props.theme.spacing.sp48} auto ${(props) => props.theme.spacing.sp42};
    width: 100%;
    display: flex;
    padding: ${(props) => props.theme.spacing.sp48} ${(props) => props.theme.spacing.sp32} 0;

    flex-wrap: wrap;

    > * {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: center;
      margin-bottom: ${(props) => props.theme.spacing.sp72};

      ${ResponsiveMediaQuery('tablet')} {
        flex: 0 0 46%;

        &:hover .categoryLink {
          opacity: 1;
        }
      }
    }
  `,

  CategoryLink: styled.div`
    float: right;
    margin-top: ${(props) => props.theme.spacing.sp16};
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease;

    ${ResponsiveMediaQuery('tablet')} {
      opacity: 0;
    }

    svg {
      width: 14px;
      height: 14px;
      margin: 3px 0 0 5px;
    }
  `,

  Item: styled(Flex)`
    svg {
      width: 42px;
      height: 42px;
      margin-top: 10px;

      ${ResponsiveMediaQuery('mobile')} {
        width: 96px;
        height: 103px;
      }
    }
  `,

  Description: styled(Flex)`
    text-align: left;
    margin-left: 16px;
    justify-content: flex-start;

    ${ResponsiveMediaQuery('mobile')} {
      margin-left: 36px;
    }

    h5 {
      margin-bottom: 10px;
    }
  `,

  Button: styled(Button.xxLarge)`
    margin: 0 auto;
    display: block;
  `
};

import styled from 'styled-components';
import Flex from 'src/styles/Flex';
import Text from 'src/styles/Text';
import Tile from 'src/styles/Tile';
import ResponsiveMediaQuery from 'src/styles/ResponsiveMediaQuery';

export default {
  Wrapper: styled(Flex)`
    background: ${(props) => props.theme.colors.secondary};
    padding: ${(props) => props.theme.spacing.sp96} 0;
    margin-top: ${(props) => props.theme.spacing.sp72};

    .faqContainer {
      display: flex;
      flex-wrap: wrap;

      ${ResponsiveMediaQuery('tablet')} {
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  `,

  LeftColumn: styled(Flex)`
    flex-direction: column;
    flex: 0 0 100%;
    margin-bottom: ${(props) => props.theme.spacing.sp48};

    ${ResponsiveMediaQuery('tablet')} {
      flex: 0 0 27%;
    }
  `,

  Subtitle: styled(Text.Paragraph)`
    width: 100%;
    justify-content: space-between;
    color: ${(props) => props.theme.colors.light};
    margin: ${(props) => props.theme.spacing.sp16} 0;
  `,

  Title: styled(Text.H2)`
    width: 100%;
    justify-content: space-between;
    margin: ${(props) => props.theme.spacing.sp16} 0;
    color: ${(props) => props.theme.colors.light};
  `,

  FlavorText: styled(Text.SmallParagraph)`
    color: ${(props) => props.theme.colors.light};
  `,

  ArticlesLink: styled(Flex)`
    height: 38px;
    align-items: flex-end;

    p {
      margin-right: ${(props) => props.theme.spacing.sp8};
      line-height: 20px;
    }
  `,

  Grid: styled(Flex)`
    flex-direction: column;
    flex: 0 0 100%;

    ${ResponsiveMediaQuery('tablet')} {
      flex: 0 0 69%;
    }

    ${Tile} {
      background: none;
      border-top: 1px solid ${(props) => props.theme.colors.borderLight1};

      span {
        display: none;
      }

      h5,
      h6,
      p {
        color: ${(props) => props.theme.colors.light};
      }

      a {
        color: ${(props) => props.theme.colors.primary};
      }

      .faq__answer * {
        color: ${(props) => props.theme.colors.light};
      }
    }
  `
};

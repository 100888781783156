import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgPlus(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={24} height={24} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <path
            d="M13 3l-.001 7.999L21 11v2l-8.001-.001L13 21h-2l-.001-8.001L3 13v-2l7.999-.001L11 3h2z"
            className="fillSecondary"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgPlus;

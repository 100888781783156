import React from 'react';
import LatestPostStyles from './styles';
import Link from 'next/link';

//styles
import Text from 'src/styles/Text';

//components
import Card from 'src/components/Blog/Card';
import ArrowRight from 'src/components/Icon/16x16/ArrowRight';

//apollo
import { FormattedMessage } from 'react-intl';
import { useLocale } from '../../Providers/localeProvider';
import Animations from '../../../styles/Animations';
import { GetCmsExportConnectHomepageQuery } from '../../../typings/generated/cmsGraphql';
import Grid from '../../../styles/Grid';

//const animationSeconds = 0.3;
//const animationDelayMultiplier = 0.12;

// If more properties are needed or the data comes from a different place then it's time to create a fragment to get its type for this component
type LatestPostsProps = { posts?: GetCmsExportConnectHomepageQuery['posts'] };

const LatestPost: React.FC<LatestPostsProps> = ({ posts }) => {
  const locale = useLocale();

  return (
    <>
      <Animations.FadeIn delaySeconds={0.7}>
        <LatestPostStyles.Title>
          <Text.H2>Latest Articles</Text.H2>
          <Link href="/[locale]/blog" as={`/${locale}/blog`}>
            <a style={{ textDecoration: 'none', color: 'primary' }}>
              <LatestPostStyles.ArticlesLink className="categoryLink">
                <Text.Paragraph color="primary" weight={900}>
                  <FormattedMessage id="home.exportConnect.categoriesViewMoreButton" />{' '}
                </Text.Paragraph>
                <ArrowRight width="14" color="primary"></ArrowRight>
              </LatestPostStyles.ArticlesLink>
            </a>
          </Link>
        </LatestPostStyles.Title>
      </Animations.FadeIn>
      <Animations.FadeIn delaySeconds={0.7}>
        <Grid columns={3} gapSize="24px">
          {posts &&
            posts?.nodes?.map((post, index) => {
              return (
                <div key={index}>
                  <Link href={`/[locale]/blog/[postSlug]`} as={`/${locale}/blog/${post?.postIdentification?.postslug}`}>
                    <a style={{ textDecoration: 'none', color: 'primary' }}>
                      <Card
                        image={post?.featuredImage?.node?.mediaItemUrl as string | null}
                        title={post?.title as string}
                        content={post?.content as string}
                      />
                    </a>
                  </Link>
                </div>
              );
            })}
        </Grid>
      </Animations.FadeIn>
    </>
  );
};
export default LatestPost;
